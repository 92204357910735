import { useDecision } from '@optimizely/react-sdk';
import { OPTIMIZELY_FRONTLINE_SIDEBAR } from '../../../consts/optimizely';
import Sidebar from './Sidebar';

export function OldSidebar(): JSX.Element {
  const [frontlineSidebarFlag, frontlineSidebarFlagReady, timeout] = useDecision(
    OPTIMIZELY_FRONTLINE_SIDEBAR
  );

  if (frontlineSidebarFlagReady || timeout) {
    if (frontlineSidebarFlag.enabled === false) {
      return <Sidebar />;
    }
  }
  return null;
}
